import * as React from "react";
import Layout from "../../layout";
import { graphql } from "gatsby";
import get from "lodash/get";

const SingleNews = ({ data, pageContext: { uid } }) => {
  const newsItem = get(data, "prismicNews.data", {});

  return (
    <Layout title={newsItem.title.text} description={newsItem.post_date}>
      <div className="container mb-5">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-lg-10 col-xl-8 mb-5">
            <div
              dangerouslySetInnerHTML={{ __html: newsItem.body.html }}
              className={"mb-5"}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SingleNews;

export const query = graphql`
  query SingleNewsItem($uid: String) {
    prismicNews(uid: { eq: $uid }) {
      uid
      data {
        post_date(formatString: "DD MMM yyyy")
        title {
          text
        }
        cover_image {
          url
        }
        body {
          html
        }
      }
    }
  }
`;
